
form.form,
div.panel {
    max-width: 400px;
    margin: auto;
}

img.logo {
    width: 100%
}

.btn-panel {
    background-image: radial-gradient(rgb(255,255,255), rgb(240, 240, 240));
    border: solid 1px rgb(200,200,200)
}

.panel-row .btn-panel {
    margin-bottom: 0.5em;
}

td.actions button {
    margin-right: 0.2em;
}

img.loader {
    width: 100px;
    height: 100px;
    margin: auto;
    display: block;
}

.form-group.required label:after {
    content:" *";
    color:red;
}

th.sortable {
    position: relative;
    cursor: pointer;
    padding-right: 10px;
}
  
th.sortable::after {
    font-family: "Font Awesome 5 Free";
    content: "\f0dc";
    position: absolute;
    right: 8px;
    color: #999;
}
  
th.sortable.asc::after {
    content: "\f0d8";
}
  
th.sortable.desc::after {
    content: "\f0d7";
}
  
th.sortable:hover::after {
    color: #333;
}

.addItem {
    font-size: 22px;
}

#notificationEmails.repeatable .itemContainer,
#gotoFreshNotificationEmails.repeatable .itemContainer,
#palletsNotificationEmails.repeatable .itemContainer {
    position: relative;
}

#notificationEmails.repeatable .itemContainer .removeButton,
#gotoFreshNotificationEmails.repeatable .itemContainer .removeButton,
#palletsNotificationEmails.repeatable .itemContainer .removeButton {
    position: absolute;
    left: -22px;
    top: 13px;
}

tr.inactive {
    color: #CCC;
}

#extraRouteDestinations.repeatable .itemContainer {
    position: relative;
}

#extraRouteDestinations.repeatable .itemContainer .removeButton {
    position: absolute;
    left: -22px;
    top: 6px;

}

label.required::after {
    content: " *";
    color: red;
}

#registrationNumber {
    text-transform: uppercase;
}
